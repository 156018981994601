import React from 'react';
import Helmet from 'react-helmet';

import BaseLayoutNoContact from './base-no-contact';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

import './base.scss';
import ContactSection from '../components/ContactSection';
import CookiesModal from '../components/CookiesModal/CookiesModal';

export default function BaseLayout({ children, pageContext }) {

  if (pageContext.layout === 'no-contact') {
    return <BaseLayoutNoContact>{children}</BaseLayoutNoContact>;
  }

  return (
    <>
      <Helmet>
        <title>SolveQ Software Development IoT React Node Apps</title>
      </Helmet>

      <Header />
      <div className="th-page-content">{children}</div>
      <br />
      <br />
      <br />
      <ContactSection />
      <Footer />
      <CookiesModal />
    </>
  );
}
