import React, { useEffect } from 'react';
import { Link } from 'gatsby';

import './OffcanvasNavigation.scss';

const OffcanvasNavigation = ({ closeOffcanvas }) => {
  const triggerExpand = (e) => {
    e.currentTarget.parentElement.classList.toggle('th-offcanvas-navigation__list-item--active');
  };

  useEffect(() => {
    const offcanvasNavigation = document.querySelector('.th-offcanvas-navigation');
    const offcanvasNavigationSublist = offcanvasNavigation.querySelectorAll('.th-offcanvas-navigation__sublist');
    const anchorLinks = offcanvasNavigation.querySelectorAll('a');

    for (let i = 0; i < offcanvasNavigationSublist.length; i++) {
      offcanvasNavigationSublist[i].insertAdjacentHTML(
        'beforebegin',
        `<span class="th-offcanvas-navigation__menuExpand"><i></i></span>`,
      );
    }

    const menuExpand = offcanvasNavigation.querySelectorAll('.th-offcanvas-navigation__menuExpand');
    const numMenuExpand = menuExpand.length;

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener('click', (e) => {
        triggerExpand(e);
      });
    }

    for (let i = 0; i < anchorLinks.length; i++) {
      anchorLinks[i].addEventListener('click', () => {
        closeOffcanvas();
      });
    }
  }, []);

  return (
    <nav className="th-offcanvas-navigation">
      <ul>
        <li className="th-offcanvas-navigation__list-item--has-children">
          <Link to="/#" activeClassName="current">
            What we do
          </Link>
          <ul className="th-offcanvas-navigation__sublist">
            <li>
              <Link to="/internet-of-things" activeClassName="current">
                Internet of things
              </Link>
            </li>
            <li>
              <Link to="/digital-transformation" activeClassName="current">
                Digital Transformation
              </Link>
            </li>
            <li>
              <Link to="/new-apps-and-services" activeClassName="current">
                New Apps and Services
              </Link>
            </li>
          </ul>
        </li>

        <li className="th-offcanvas-navigation__list-item--has-children">
          <Link to="/#" activeClassName="current">
            About us
          </Link>
          <ul className="th-offcanvas-navigation__sublist">
            <li>
              <Link to="/the-way-we-work" activeClassName="current">
                The way we work
              </Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/case-studies" activeClassName="current">
            Case studies
          </Link>
        </li>

        <li>
          <Link to="/careers" activeClassName="current">
            Careers
          </Link>
        </li>

        <li>
          <Link to="/contact" activeClassName="current">
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default OffcanvasNavigation;
