import React, { useEffect, useState } from 'react';
import Logo from './Logo';
import Navigation from './Navigation';
import SocialMenu from './SocialMenu';
import OffcanvasButton from './OffcanvasButton';
import Offcanvas from './Offcanvas';

import './Header.scss';

const Header = () => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    const header = document.querySelector('header');
    setHeaderTop(header.offsetTop);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`th-header ${scroll > headerTop ? 'th-header--stick' : ''}`}>
      <div className="container-lg">
        <div className="row align-items-center">
          <div className="col-xl-3 col-lg-3 col-6">
            <Logo image="solveq_logo.png" />
          </div>
          <div className="col-xl-6 col-lg-7 d-none d-lg-block">
            <Navigation />
          </div>
          <div className="col-xl-3 col-lg-2 col-6 th-header__social">
            <SocialMenu />
            <OffcanvasButton />
          </div>
        </div>
      </div>
      <Offcanvas />
    </header>
  );
};

export default Header;
