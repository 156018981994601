import React from 'react';

import OffcanvasNavigation from './OffcanvasNavigation';

import './OffcanvasContent.scss';

const OffcanvasContent = ({ closeOffcanvas }) => {
  return (
    <div className="th-offcanvas-content">
      <div className="th-offcanvas-content__inner">
        <OffcanvasNavigation closeOffcanvas={closeOffcanvas} />
      </div>
    </div>
  );
};

export default OffcanvasContent;
