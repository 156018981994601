import React from 'react';
import { MdPlace, MdLocalPhone, MdEmail } from 'react-icons/md';

import ContactForm from '../ContactForm/ContactForm';

import './contact.scss';

const ContactSection = () => (
  <>
    <div className="th-hero-page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="th-hero-content">
              <h1 className="th-hero-content__header">Contact us</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section className="th-section-contact">
      <div className="container-xl">
        <div className="row no-gutters">
          <div className="col-lg-5 th-theme-md-pb">
            <h5 className="d-none th-theme-title-small">Visit us</h5>
            <h2 className="th-theme-title-large">Visit us</h2>

            <div className="row">
              <div className="col-12">
                <div className="th-contact-info">
                  <div className="th-contact-info-icon">
                    <div className="th-theme-icon th-theme-icon--rounded">
                      <div className="th-theme-icon-inner">
                        <MdPlace size={24} />
                      </div>
                    </div>
                  </div>
                  <div className="th-contact-info-text">
                    <h3 className="th-contact-info-text__header">Office address</h3>
                    <p className="th-contact-info-text__paragraph">Mikołaja Kopernika 54, 81-411 Gdynia, Poland</p>
                  </div>
                </div>
                <div className="th-theme-separator">&nbsp;</div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="th-contact-info">
                  <div className="th-contact-info-icon">
                    <div className="th-theme-icon th-theme-icon--rounded">
                      <div className="th-theme-icon-inner">
                        <MdLocalPhone size={24} />
                      </div>
                    </div>
                  </div>
                  <div className="th-contact-info-text">
                    <h3 className="th-contact-info-text__header">Phone number</h3>
                    <p className="th-contact-info-text__paragraph">+48 501 959 657</p>
                  </div>
                </div>
                <div className="th-theme-separator">&nbsp;</div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="th-contact-info">
                  <div className="th-contact-info-icon">
                    <div className="th-theme-icon th-theme-icon--rounded">
                      <div className="th-theme-icon-inner">
                        <MdEmail size={24} />
                      </div>
                    </div>
                  </div>
                  <div className="th-contact-info-text">
                    <h3 className="th-contact-info-text__header">E-mail address</h3>
                    <p className="th-contact-info-text__paragraph">
                      <a href="mailto:info@solveq.io">info@solveq.io</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <h5 className="d-none th-theme-title-small">Interested in cooperation?</h5>
            <h2 className="th-theme-title-large">What can we do for you?</h2>

            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  </>
);

export default ContactSection;
