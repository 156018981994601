// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-list-blog-jsx": () => import("./../../../src/components/BlogList/blog.jsx" /* webpackChunkName: "component---src-components-blog-list-blog-jsx" */),
  "component---src-components-blog-post-blog-post-jsx": () => import("./../../../src/components/BlogPost/BlogPost.jsx" /* webpackChunkName: "component---src-components-blog-post-blog-post-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-case-studies-jsx": () => import("./../../../src/pages/case-studies.jsx" /* webpackChunkName: "component---src-pages-case-studies-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-digital-transformation-jsx": () => import("./../../../src/pages/digital-transformation.jsx" /* webpackChunkName: "component---src-pages-digital-transformation-jsx" */),
  "component---src-pages-gps-monitoring-heartbeat-iot-mobile-react-native-application-jsx": () => import("./../../../src/pages/gps-monitoring-heartbeat-iot-mobile-react-native-application.jsx" /* webpackChunkName: "component---src-pages-gps-monitoring-heartbeat-iot-mobile-react-native-application-jsx" */),
  "component---src-pages-gps-tracking-iot-mobile-react-native-application-jsx": () => import("./../../../src/pages/gps-tracking-iot-mobile-react-native-application.jsx" /* webpackChunkName: "component---src-pages-gps-tracking-iot-mobile-react-native-application-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-internet-of-things-jsx": () => import("./../../../src/pages/internet-of-things.jsx" /* webpackChunkName: "component---src-pages-internet-of-things-jsx" */),
  "component---src-pages-new-apps-and-services-jsx": () => import("./../../../src/pages/new-apps-and-services.jsx" /* webpackChunkName: "component---src-pages-new-apps-and-services-jsx" */),
  "component---src-pages-nuxt-vue-php-website-application-jsx": () => import("./../../../src/pages/nuxt-vue-php-website-application.jsx" /* webpackChunkName: "component---src-pages-nuxt-vue-php-website-application-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-submenu-1-jsx": () => import("./../../../src/pages/submenu1.jsx" /* webpackChunkName: "component---src-pages-submenu-1-jsx" */),
  "component---src-pages-submenu-2-jsx": () => import("./../../../src/pages/submenu2.jsx" /* webpackChunkName: "component---src-pages-submenu-2-jsx" */),
  "component---src-pages-telenor-case-study-jsx": () => import("./../../../src/pages/telenor-case-study.jsx" /* webpackChunkName: "component---src-pages-telenor-case-study-jsx" */),
  "component---src-pages-test-jsx": () => import("./../../../src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */),
  "component---src-pages-the-way-we-work-jsx": () => import("./../../../src/pages/the-way-we-work.jsx" /* webpackChunkName: "component---src-pages-the-way-we-work-jsx" */),
  "component---src-pages-traxtest-jsx": () => import("./../../../src/pages/traxtest.jsx" /* webpackChunkName: "component---src-pages-traxtest-jsx" */),
  "component---src-pages-vue-html-canvas-browser-application-jsx": () => import("./../../../src/pages/vue-html-canvas-browser-application.jsx" /* webpackChunkName: "component---src-pages-vue-html-canvas-browser-application-jsx" */)
}

