import React, { useState } from 'react';
import axios from 'axios';
import useForm from './useForm';

import './ContactForm.scss';

const mailerEndpointUrl = `https://solveq.usermd.net/mail/sender.php`;

function Form() {
  const [isSent, setIsSent] = useState(false);
  const stateSchema = {
    username: { value: '', error: '' },
    phone: { value: '', error: '' },
    email: { value: '', error: '' },
    company: { value: '', error: '' },
    subject: { value: '', error: '' },
    message: { value: '', error: '' },
  };

  const stateValidatorSchema = {
    username: {
      required: true,
      validator: {
        func: (value) => /^(.)*$/.test(value),
        error: 'Invalid Name name format.',
      },
    },
    phone: {
      required: false,
      validator: {
        func: (value) => /^[\d\s-]*$/.test(value),
        error: 'Invalid Phone format.',
      },
    },
    email: {
      required: true,
      validator: {
        func: (value) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value),
        error: 'Invalid Email format.',
      },
    },
    company: {
      required: false,
      validator: {
        func: (value) => /^(.)*$/.test(value),
        error: 'Invalid Company format.',
      },
    },
    subject: {
      required: false,
      validator: {
        func: (value) => /^(.)*$/.test(value),
        error: 'Invalid Subject format.',
      },
    },
    message: {
      required: true,
      validator: {
        func: (value) => /^(.)*$/.test(value),
        error: 'Invalid Message format.',
      },
    },
  };

  function onSubmitForm(state) {
    axios
      .post(
        mailerEndpointUrl,
        { ...state },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        },
      )
      .then((res) => {
        if (res.data === 'ok') {
          setIsSent(true);
        }
      });
  }

  const { values, errors, dirty, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm,
  );

  const { username, phone, email, company, subject, message } = values;

  return isSent ? (
    <div className="row">
      <div className="col-12">
        <div className="alert alert-success th-alert-success" role="alert">
          Thank you, your inquiry has been sent. We will contact you as soon as possible.
        </div>
      </div>
    </div>
  ) : (
    <form id="th-contact-form" className="row" onSubmit={handleOnSubmit}>
      <div className="col-sm-6 col-md-4">
        <div className="form-group th-contact-form-field-group">
          <div className="th-contact-form-field-error">
            {errors.username && dirty.username && <p className="error">{errors.username}</p>}
          </div>
          <input
            name="username"
            className="form-control"
            placeholder="Name*"
            required
            onChange={handleOnChange}
            value={username}
          />
        </div>
      </div>

      <div className="col-sm-6 col-md-4">
        <div className="form-group">
          <div className="th-contact-form-field-error">
            {errors.phone && dirty.phone && <p className="error">{errors.phone}</p>}
          </div>

          <input
            name="phone"
            type="text"
            placeholder="Phone Number"
            className="form-control"
            onChange={handleOnChange}
            value={phone}
          />
        </div>
      </div>

      <div className="col-sm-12 col-md-4">
        <div className="form-group">
          <div className="th-contact-form-field-error">
            {errors.email && dirty.email && <p className="error">{errors.email}</p>}
          </div>

          <input
            name="email"
            type="email"
            placeholder="Email Address*"
            required
            className="form-control"
            onChange={handleOnChange}
            value={email}
          />
        </div>
      </div>

      <div className="col-sm-12 col-md-12">
        <div className="form-group">
          <div className="th-contact-form-field-error">
            {errors.company && dirty.company && <p className="error">{errors.company}</p>}
          </div>

          <input
            name="company"
            type="text"
            placeholder="Company"
            className="form-control"
            onChange={handleOnChange}
            value={company}
          />
        </div>
      </div>

      <div className="col-sm-12 col-md-12">
        <div className="form-group">
          <div className="th-contact-form-field-error">
            {errors.subject && dirty.subject && <p className="error">{errors.subject}</p>}
          </div>

          <input
            name="subject"
            type="text"
            placeholder="Subject"
            className="form-control"
            onChange={handleOnChange}
            value={subject}
          />
        </div>
      </div>

      <div className="col-sm-12 col-md-12">
        <div className="form-group">
          <div className="th-contact-form-field-error">
            {errors.message && dirty.message && <p className="error">{errors.message}</p>}
          </div>

          <textarea
            name="message"
            rows={5}
            placeholder="Message*"
            required
            className="form-control"
            onChange={handleOnChange}
            value={message}
          />
        </div>
      </div>

      <div className="col-md-12">
        <div className="text-center">
          <button type="submit" id="submit" disabled={disable}>
            Submit
          </button>
        </div>
      </div>
    </form>
  );
}

export default Form;
