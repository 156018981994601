import React, {useState, useEffect} from 'react';

import './CookiesModal.scss';



const CookiesModal = () => {
  const handleCloseClick = () => {
    setVisible(false)
  }

  const handleAcceptClick = () => {
    localStorage.setItem('cookie', 'shown')
    setVisible(false)
  }

  useEffect(() => {
    if (localStorage.getItem('cookie') === 'shown') {
      setVisible(false)
    }
  }, [])

  const [visible, setVisible] = useState(true);
  return (
    <div className={`th-cookies-modal ${ visible ? 'visible' : 'hidden'}`}>
      <div className="th-cookies-close font-weight-bold" onClick={() => handleCloseClick()}>X</div>

      <h2 className="text-center">Allow Cookies</h2>

      <p className="text-center">
        Our website uses cookies to improve your experience. You can disable them in your browser at any time. <a
        href="/privacy-policy">Learn more</a>
      </p>

      <div className="w-100 text-center">
        <button type="button" className="btn btn-danger" onClick={() => handleAcceptClick()}>Accept Cookies</button>
      </div>
    </div>

  )
}

export default CookiesModal;
