import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Logo = ({ image }) => {
  return (
    <div className="th-header__logo">
      <Link to="/">
        <img src={`/${image}`} alt="logo images" className="img-fluid" />
      </Link>
    </div>
  );
};

Logo.defaultProps = {
  image: 'solveq_logo.png',
};

Logo.propTypes = {
  image: PropTypes.string,
};

export default Logo;
