import React from 'react';
import Helmet from 'react-helmet';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import CookiesModal from '../components/CookiesModal/CookiesModal';

import './base.scss';

export default function BaseLayoutNoContact({ children }) {
  return (
    <>
      <Helmet>
        <title>SolveQ Software Development IoT React Node Apps</title>
      </Helmet>
      <Header />
      <div className="th-page-content">{children}</div>
      <br />
      <br />
      <br />
      <Footer />
      <CookiesModal />
    </>
  );
}
