import React from 'react';
import { MdMenu } from 'react-icons/md';

import './OffcanvasButton.scss';

const OffcanvasButton = () => {
  const openOffcanvas = () => {
    const offcanvas = document.querySelector('.th-offcanvas');
    offcanvas.classList.add('th-offcanvas--active');
  };

  return (
    <div className="th-offcanvas-button d-block d-lg-none text-right">
      <button type="button" onClick={() => openOffcanvas()}>
        <MdMenu />
      </button>
    </div>
  );
};

export default OffcanvasButton;
