import React from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

import './SocialMenu.scss';

const SocialMenu = () => {
  return (
    <div className="th-social d-none d-lg-block">
      <a href="https://pl.linkedin.com/company/solveq" target="_blank" rel="noreferrer">
        <FaLinkedin />
      </a>
      <a href="https://www.facebook.com/solveq" target="_blank" rel="noreferrer">
        <FaFacebook />
      </a>
      <a href="https://www.instagram.com/solveq_sh/" target="_blank" rel="noreferrer">
        <FaInstagram />
      </a>
      <a href="https://twitter.com/solveqhq" target="_blank" rel="noreferrer">
        <FaTwitter />
      </a>
    </div>
  );
};

export default SocialMenu;
