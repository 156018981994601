import React from 'react';
import { IoMdClose } from 'react-icons/io';

import OffcanvasContent from './OffcanvasContent';

import './Offcanvas.scss';

const Offcanvas = () => {
  const closeOffcanvas = () => {
    const offcanvas = document.querySelector('.th-offcanvas');
    offcanvas.classList.remove('th-offcanvas--active');
  };

  return (
    <div className="th-offcanvas">
      <button type="button" onClick={() => closeOffcanvas()}>
        <IoMdClose />
      </button>
      <OffcanvasContent closeOffcanvas={closeOffcanvas} />
    </div>
  );
};

export default Offcanvas;
